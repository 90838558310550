import App, { AppProps } from "next/app"
import getConfig from "next/config"
import Head from "next/head"
import React from "react"
import styled from "styled-components"

import { CustomError } from "~/components/CustomError"
import { GlobalStyle } from "~/components/GlobalStyle"
import { distribution, google } from "~/config"
import { Fetcher } from "~/external/Fetcher"
// import { initSentry, reportError } from "~/libs/sentry"
import { color } from "~/libs/style"
import { ConfigRepository } from "~/repository/ConfigRepository"

type GameAppState = {
  eventId: string
  hasError: boolean
}

export default class GameApp extends App<Record<string, unknown>, Record<string, unknown>, GameAppState> {
  public state = {
    eventId: "",
    hasError: false
  }

  constructor(props: AppProps) {
    super(props)
    // if (typeof window !== "undefined") {
    //   initSentry()
    // }
  }

  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  // componentDidCatch(error: Error) {
  //   const eventId = reportError(error)
  //   this.setState({ eventId })
  // }

  static async getInitialProps() {
    const {
      serverRuntimeConfig: { cmsEndpoint, cmsApiKey, configDomain }
    } = getConfig()
    const baseUrl = configDomain[distribution]
    const fetcher = new Fetcher({ cmsEndpoint, cmsApiKey })

    const configRepo = new ConfigRepository(fetcher)
    const config = await configRepo.findAll()

    return { pageProps: {}, isOptimizeRunning: !!config.experiments && config.experiments.length > 0, baseUrl }
  }

  render() {
    const { Component, pageProps, baseUrl } = this.props

    return (
      <>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=0"
          />
          <meta name="format-detection" content="telephone=no" />

          <link rel="icon" href={`${baseUrl}assets/img/favicon.ico`} />
          <link rel="apple-touch-icon-precomposed" href={`${baseUrl}assets/img/apple-touch-icon.png`} />
          <link
            rel="preload"
            crossOrigin="anonymous"
            href="https://cdn.reproio.com/web/v2/repro-sdk.min.js"
            as="script"
          />
          <link rel="preconnect" href="//d.socdm.com" crossOrigin="anonymous" />
          <link rel="preconnect" href="//tg.socdm.com" crossOrigin="anonymous" />
          <link rel="preconnect" href="//www.googletagmanager.com" crossOrigin="anonymous" />
          <link rel="preconnect" href="//www.google-analytics.com" crossOrigin="anonymous" />

          {/* Analytics Tracking */}
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

ga('create', '${google.gaTrackingId}', {name: '${google.gaTrackerName}', allowLinker: false, cookieDomain: 'auto'});
`
            }}
          />
          {/* Google Tag Manager */}
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NKQ9M89');`
            }}
          />
          <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
          <script
            defer
            dangerouslySetInnerHTML={{
              __html: `window.googletag = window.googletag || { cmd: [] };
              googletag.cmd.push(function () {
                const slot1 = googletag.defineSlot('/49720294/auportal_sp_easygame_top_1_320x100', [320, 100], 'div-gpt-ad-1681812009443-0').addService(googletag.pubads());
                const slot2 = googletag.defineSlot('/49720294/auportal_sp_easygame_top_2_rectangle', [[336, 280], [300, 250]], 'div-gpt-ad-1681812404902-0').addService(googletag.pubads());
                const slot3 = googletag.defineSlot('/49720294/auportal_sp_easygame_top_3_rectangle', [[300, 250], [336, 280]], 'div-gpt-ad-1681812246209-0').addService(googletag.pubads());
                const slot4 = googletag.defineSlot('/49720294/auportal_sp_easygame_top_overlay_320x50', [320, 50], 'div-gpt-ad-1681812322341-0').addService(googletag.pubads());
                googletag.pubads().enableSingleRequest();
                googletag.enableServices();
                
                // BIDSTRAP（HB）が設置されていないページでは以下は実行しない
                if ('bsttag' in window) {
                  bsttag.launchAuction([slot1, slot2, slot3, slot4]);
                  // 4 slots とも纏めて呼び出す
                }
              });`
            }}
          />
        </Head>
        <GlobalStyle />
        {this.state.hasError ? <Error eventId={this.state.eventId} /> : <Component {...pageProps} />}
      </>
    )
  }
}

const Error: React.FC<{ eventId: string }> = ({ eventId }) => {
  return (
    <CustomError title="ページを表示できません">
      問題が発生しているため
      <br />
      ページを表示できません。
      <br />
      申し訳ございません。
      <EventId>エラー： {eventId}</EventId>
    </CustomError>
  )
}

const EventId = styled.div`
  margin: 1em;
  font-size: 12px;
  color: ${color.lightGray};
`
